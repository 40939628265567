/*******************************/
/********* General CSS *********/
/*******************************/

/* @font-face {
  font-family: theLogoFont; 
    src: url(/src/fonts/YuGothicUI-Bold.ttf);
    src: url(/src/fonts/YuGothM.ttc);
} */

body {
  color: #666666;
  background: #ffffff;
  font-weight: 400;
}

h1,
h2, 
h3, 
h4,
h5, 
h6 {
  font-weight: 100;
}

a {
  color: #454545;
  transition: .3s;
}

a:hover,
a:active,
a:focus {
  color: #333333;
  outline: none;
  text-decoration: none;
}

.btn:focus {
  box-shadow: none;
}

.wrapper {
  position: relative;
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  background: #ffffff;
}

.back-to-top {
  position: fixed;
  display: none;
  background: #FFD662;
  color: #000000;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 22px;
  border-radius: 5px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 9;
}

.back-to-top:hover {
  color: #ffffff;
  background: #00539C;
}

.back-to-top i {
  padding-top: 10px;
}


/*******************************/
/********** Header CSS *********/
/*******************************/
.header {
  position: relative;
  width: 100%;
  /* padding: 15px 0 0 0; */
  /*background: #00539C;*/
/*height: 1000px;*/
padding-bottom: 15px;
}

.header.home {
  margin-bottom: 45px;
  background: url(/src/img/hero2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background: blue; */
}

@media (max-width: 766px) {
  .header.home {
    margin-bottom: 45px;
    background-position: center;
    background-repeat: no-repeat;
    background: #CCDEEC;
  }
}

@media (min-width: 992px) {
  .header .row {
      padding: 0 60px;
  }
}

.header .header-top {
  padding-bottom: 15px;
}

.header .brand {
  position: relative;
  /* font-family:  theLogoFont; */
}

.header .brand a {
  color: #61a18d;
  font-size: 60px;
  font-weight: 100;
}

.header .brand img {
  width: 100%;
  max-width: 150px;
  height: 100%;
  max-height: 100px;

}

.header .topbar {
  position: relative;
  text-align: right;
}

.header .topbar .topbar-col {
  display: inline-block;
  padding: 0 15px;
  margin-bottom: 15px;
}

.header .topbar-col a {
  color: #ffffff;
  font-size: 13px;
}

.header .topbar-col a i {
  margin-right: 10px;
  color: #FFD662;
}

.header .topbar-social {
  position: relative;
}

.header .topbar-social a {
  display: inline-block;
}

.header .topbar-social a i {
  margin-right: 10px;
  font-size: 18px;
  color: #ffffff;
}

.header .topbar-social a:last-child i {
  margin: 0;
}

.header .topbar-social a:hover i {
  color: #FFD662;
}

.header .dropdown-menu {
  margin-top: 0;
  border: 0;
  border-radius: 5px;
  background: #eeeeee;
}

.header .dropdown-menu a.active {
  color: #FFD662;
}

@media (min-width: 992px) {
  .header .navbar {
      background: transparent !important;
  }
  
  .header .navbar-brand {
      display: none;
  }
  
  .header .navbar-light a.nav-link,
  .header .navbar-light .show > .nav-link {
      position: relative;
      padding: 8px 10px;
      color: #1d1d1f; /* color of navbar elements*/
      font-size: 16px;
      z-index: 1;
  }
  
  .header .navbar-light a.nav-link:hover,
  .header .navbar-light a.nav-link.active {
      color: #61A18D; /* activae navbar element */
  font-weight: bold;
  }
}

@media (max-width: 992px) {
  .header .brand {
      text-align: center;
      margin-bottom: 15px;
  }
  
  .header .topbar {
      text-align: center;
      margin-bottom: 15px;
  }
  
  .header .topbar .topbar-col {
      margin: 0;
  }
  
  .header .topbar .topbar-social {
      margin-top: 5px;
  }
  
  .header .navbar {
      background: #ffffff !important;
      border: none;
      z-index: 1;
  }
  
  .header a.nav-link {
      padding: 5px 0;
  }
  
  .header .dropdown-menu {
      box-shadow: none;
  }
}

.header .navbar .btn {
  position: relative;
  margin-left: 15px;
  padding: 12px 20px 10px 20px;
  font-size: 13px;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  background: #61a18d;
  transition: .3s;
}

.header .navbar .btn:hover {
  color: #000000;
  background: #FFD662;
}

.header .navbar .btn i {
  font-size: 16px;
  margin-right: 8px;
}

@media (max-width: 992px) {
  .header .navbar {
      border-radius: 5px;
  }
  
  .header .navbar .btn {
      display: none;
  }
}

.header .hero {
  padding-top: 60px;
  padding-bottom: 60px;

}

.header .hero .content{
  margin-top: 50px
}

.header .hero h2 {
  margin: 0;
  font-size: 60px;
  font-weight: 100;
  color: #3f3f3f;
}

.header .hero h2 span {
  color: #61A18D;
}

.header .hero p {
  margin: 10px 0 20px 0;
  font-size: 22px;
  color: #3f3f3f;
}

/* @media screen and (max-device-width: 764px) {
  .header .hero p {
    margin: 10px 0 20px 0;
    font-size: 22px;
    color: #fff;
  }
} */

/* @media screen and (max-device-width: 396px) {
  .header .hero p {
    margin: 10px 0 20px 0;
    font-size: 22px;
    color: #fff;
  }
} */


.header .hero a.btn {
  position: relative;
  padding: 15px 30px;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffff;
  border: none;
  border-radius: 5px;
  background: #61a18d;;
  transition: .3s;
}

.header .hero a.btn:hover {
  color: #000000;
  background: #FFD662;
}

.header .hero .form {
  padding: 30px;
  border: 1px solid rgba(255, 255, 255, .4);
  background: rgba(255, 255, 255, .8); /* form enviar */
  border-radius: 5px;
}

.header .hero .form h3 {
  color: #1d1d1f;
/* color: #ffffff; */
  font-weight: 300;
}

.header .hero .form input,
.header .hero .form textarea {
  margin-bottom: 15px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  /* background: rgba(0, 0, 0, 0.02); */
/*color: black;
font-weight: 600;*/
color: black;
font-weight: 600;
}

.header .hero .form input:focus,
.header .hero .form select:focus {
  background: white
}

.header .hero .form select{
  margin-bottom: 15px;
  height: 45px;
   border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  /* background: rgba(0, 0, 0, 0.02); */
  color: rgba(0, 0, 0, .5); 
  font-weight: 600;



}



.header .hero .form input::placeholder{
  color: rgba(0, 0, 0, .5);
font-weight: 600;
}



.header .hero .form .btn {
  position: relative;
  padding: 10px 35px;
  font-size: 16px;
  text-transform: uppercase;
  color: #000000;
  border: none;
  border-radius: 5px;
  background: #FFD662;
  transition: .3s;
}

.header .hero .form .btn:hover {
  color: #ffffff;
  background: #00539C;
}

@media (max-width: 767.98px) {
  .header .hero .form {
      margin-top: 45px;
  }
}


/*******************************/
/******* Page Header CSS *******/
/*******************************/
.page-header {
  position: relative;
  margin-bottom: 45px;
  padding: 90px 0;
  text-align: center;
  background: #FFD662;
}

.page-header h2 {
  color: #000000;
  font-size: 60px;
  font-weight: 100;
  text-transform: uppercase;
  margin-bottom: 15px;

}

.page-header a {
  position: relative;
  padding: 0 12px;
  font-size: 22px;
  font-weight: 300;
  color: #00539C;
}

.page-header a:hover {
  color: #000000;
}

.page-header a::after {
  position: absolute;
  content: "/";
  width: 8px;
  height: 8px;
  top: -2px;
  right: -7px;
  text-align: center;
  color: #666666;
}

.page-header a:last-child::after {
  display: none;
}


/*******************************/
/******* Section Header ********/
/*******************************/
.section-header {
  position: relative;
  width: 100%;
  max-width: 550px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 60px;
  padding-bottom: 15px;

}

.section-header::before {
  position: absolute;
  content: "";
  width: 100px;
  height: 3px;
  left: calc(50% - 50px);
  bottom: 0;
  background: linear-gradient(to left, #FFD662, #00539C, #FFD662);
  border-radius: 100%;
}

.section-header::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 9px;
  left: calc(50% - 10px);
  bottom: -3px;
  background: #00539C;
  border-radius: 5px;
}

.section-header p {
  margin-bottom: 10px;
  font-size: 20px;
}

.section-header h2 {
  color: #00539C;
  font-size: 50px;
  font-weight: 100;
}

.section-header.left {
  text-align: left;
}

.section-header.left::before {
  width: 60px;
  left: 0;
  background: linear-gradient(to left, #FFD662, #00539C, #00539C);
  border-radius: 0 100% 100% 0;
}

.section-header.left::after {
  left: 0;
  border-radius: 5px;
}


/*******************************/
/********** About CSS **********/
/*******************************/
.about {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.about .about-img img {
  width: 100%;
  border-radius: 5px;
}

.about .about-text h2 {
  position: relative;
  color: #00539C;
  font-size: 50px;
  margin-bottom: 15px;
}

.about .about-text h2 span {
  font-size: 120px;
}

.about .about-text p {
  font-size: 16px;
  margin-bottom: 30px;
}

.about .about-text a.btn {
  padding: 15px 35px;
  font-size: 16px;
  text-transform: uppercase;
  color: #000000;
  border: none;
  border-radius: 5px;
  background: #FFD662;
  transition: .3s;
}

.about .about-text a.btn:hover {
  color: #ffffff;
  background: #00539C;
}

@media (max-width: 767.98px) {
  .about .about-img img {
      margin-bottom: 30px;
  }
}


/*******************************/
/********* Service CSS *********/
/*******************************/
.service {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.service .service-item {
  position: relative;
  width: 100%;
  text-align: center;
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 30px;
  box-shadow: 0 0 30px rgba(0, 0, 0, .1);
}

.service .service-item img {
  width: 100%;
  border-radius: 5px 5px 0 0;
}

.service .service-item h3 {
  margin: 0;
  padding: 25px 15px 15px 15px;
  font-size: 20px;
  font-weight: 300;
  color: #00539C;
}

.service .service-item p {
  margin: 0;
  padding: 0 25px 15px 25px;
  font-size: 16px;
}

.service .service-item .icon{
  margin-bottom: 20px;
}
.service .service-item a.btn {
  position: relative;
  margin-bottom: 30px;
  padding: 10px 20px;
  font-size: 14px;
  text-transform: uppercase;
  color: #000000;
  border: none;
  border-radius: 5px;
  background: #FFD662;
  transition: .3s;
}

.service .service-item:hover a.btn {
  color: #ffffff;
  background: #00539C;
}

.service .service-item a.btn:hover {
  color: #000000;
  background: #FFD662;
}


/*******************************/
/********* Feature CSS *********/
/*******************************/
.feature {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.feature .section-header {
  margin-bottom: 30px;
}

.feature a.btn {
  margin-top: 10px;
  padding: 15px 35px;
  font-size: 16px;
  text-transform: uppercase;
  color: #000000;
  border: none;
  border-radius: 5px;
  background: #FFD662;
  transition: .3s;
}

.feature a.btn:hover {
  color: #ffffff;
  background: #00539C;
}

.feature .feature-item {
  margin-bottom: 30px;
}

.feature .feature-item img {
  width: 100%;
  border-radius: 5px;
}

.feature .feature-item h3 {
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: 300;
  color: #00539C;
}

.feature .feature-item p {
  margin: 0;
  font-size: 16px;
}

@media (max-width: 767.98px) {
  .feature a.btn {
      margin-bottom: 45px;
  }
}


/*******************************/
/*********** Team CSS **********/
/*******************************/
.team {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.team .team-item {
  margin-bottom: 30px;
}

.team .team-img {
  position: relative;
  font-size: 0;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.team .team-img img {
  width: 100%;
  height: auto;
}

.team .team-text {
  padding: 150px 15px 30px 15px;
  margin-top: -130px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #eeeeee;
  transition: .3s;
}

.team .team-text:hover {
  background: #FFD662;
  border: 1px solid #FFD662;
}

.team .team-text h2 {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 400;
  color: #00539C;
}

.team .team-text h3 {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
}

.team .team-social {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
}

.team .team-social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  padding: 11px 0 10px 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  transition: .3s;
}

.team .team-social .icona {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 6px;
  margin-left: 6px;
  /* padding: 11px 0 10px 0; */
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  /* text-align: center; */
  color: #ffffff;
  border-radius: 5px;
  transition: .3s;
}

.team .team-social a.social-tw {
  background: #00acee;
}

.team .team-social a.social-fb {
  background: #3b5998;
}

.team .team-social a.social-li {
  background: #0e76a8;
}

.team .team-social a.social-in {
  background: #3f729b;
}

.team .team-social a.social-yt {
  background: #c4302b;
}

.team .team-social a:last-child {
  margin-right: 0;
}

.team .team-social a:hover {
  color: #ffffff;
  background: #00539C;
}


/*******************************/
/*********** FAQs CSS **********/
/*******************************/
.faqs {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.faqs .section-header {
  margin-bottom: 30px;
}

.faqs img {
  width: 100%;
  border-radius: 5px;
}

@media(max-width: 767.98px) {
  .faqs img {
      margin-bottom: 30px;
  }
}

.faqs .card {
  margin-bottom: 15px;
  padding-top: 15px;
  border: none;
  border-radius: 0;
  border-top: 1px solid #eeeeee;
}

.faqs .card:last-child {
  margin-bottom: 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #eeeeee;
}

.faqs .card-header {
  padding: 0;
  border: none;
  background: #ffffff;
}

.faqs .card-header a {
  display: block;
  width: 100%;
  color: #00539C;
  font-size: 18px;
  font-weight: 300;
  line-height: 40px;
}

.faqs .card-header a span {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  text-align: center;
  background: #FFD662;
  color: #000000;
  border-radius: 5px;
}

.faqs .card-header [data-toggle="collapse"]:after {
  font-family: 'font Awesome 5 Free';
  content: "\f067";
  float: right;
  color: #00539C;
  font-size: 12px;
  font-weight: 900;
  transition: .3s;
}

.faqs .card-header [data-toggle="collapse"][aria-expanded="true"]:after {
  font-family: 'font Awesome 5 Free';
  content: "\f068";
  float: right;
  color: #00539C;
  font-size: 12px;
  font-weight: 900;
  transition: .3s;
}

.faqs .card-body {
  padding: 15px 0 0 0;
  font-size: 16px;
  border: none;
  background: #ffffff;
}

.faqs a.btn {
  margin-top: 30px;
  padding: 15px 35px;
  font-size: 16px;
  text-transform: uppercase;
  color: #000000;
  border: none;
  border-radius: 5px;
  background: #FFD662;
  transition: .3s;
}

.faqs a.btn:hover {
  color: #ffffff;
  background: #00539C;
}


/*******************************/
/****** Pricing Plan CSS *******/
/*******************************/
.price {
  position: relative;
  padding: 45px 0 15px 0;
}

.price .price-item {
  text-align: center;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 30px rgba(0, 0, 0, .1);
  margin-bottom: 30px;
}

.price .price-header {
  padding: 30px 0 50px 0;
  color: #000000;
  background: #FFD662;
  border-radius: 5px 5px 0 0;
}

.price .featured-item .price-header {
  color: #ffffff;
  background: #00539C;
}

.price .price-icon {
  margin-bottom: 15px;
}

.price .price-icon i {
  display: inline-block;
  font-size: 45px;
}

.price .price-title h2 {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 2px;
}

.price .price-pricing h2 {
  font-size: 60px;
  font-weight: 100;
  margin-left: 15px;
  margin-bottom: 0;
}

.price .price-pricing h2 small {
  position: absolute;
  font-size: 18px;
  font-weight: 300;
  margin-top: 16px;
  margin-left: -15px;
}

.price .price-body {
  padding: 20px 0;
  background: #ffffff;
  border-radius: 25px 30px 0 0;
  margin-top: -30px;
}

.price .price-des ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.price .price-des ul li {
  color: #2A293E;
  font-size: 14px;
  font-weight: 300;
  padding: 5px;
  border-bottom: 1px solid #eeeeee;
}

.price .price-des ul li:last-child {
  border: none;
}

.price .price-footer {
  padding-bottom: 30px;
  background: #ffffff;
  border-radius: 0 0 5px 5px;
}


.price .price-action a {
  display: inline-block;
  padding: 10px 25px;
  color: #000000;
  font-size: 14px;
  letter-spacing: 2px;
  background: #FFD662;
  border-radius: 5px;
  transition:  .3s;
}

.price .price-action a i {
  margin-right: 5px;
}

.price .price-action a:hover {
  color: #ffffff;
  background: #00539C;
}

.price .featured-item .price-action a {
  color: #ffffff;
  background: #00539C;
}

.price .featured-item .price-action a:hover {
  color: #000000;
  background: #FFD662;
}


/*******************************/
/********** Newsletter *********/
/*******************************/
.newsletter {
  position: relative;
  margin: 45px 0;
  padding: 60px 0;
  background: #CCDDEB;
}

.newsletter h2 {
  color: #666666;
  font-size: 45px;
  font-weight: 100;
  text-transform: uppercase;
}

.newsletter p {
  margin: 0;
  color: #666666;
  font-size: 20px;
  font-weight: 400;

}

.newsletter .form {
  position: relative;
  
}

.newsletter input,
.newsletter select{
  height: 60px;
  border: none;
  border-radius: 5px;
  margin-bottom: 15px;
  height: 45px;
}

.newsletter .btn {
  /* position: absolute;
  top: 5px;
  right: 5px;
  height: 50px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight:400;
  text-transform: uppercase;
  color: #fff;
  background: #00539C;
  border-radius: 5px;
  transition: .3s; */
  position: relative;
  padding: 10px 35px;
  font-size: 16px;
  text-transform: uppercase;
  color: #000000;
  border: none;
  border-radius: 5px;
  background: #FFD662;
  transition: .3s;
}

.newsletter .btn:hover {
  color: #ffffff;
  background: #00539C; 
}


/*******************************/
/******* Testimonial CSS *******/
/*******************************/
.testimonial {
  position: relative;
  padding: 45px 0;
}

.testimonial .testimonial-item {
  position: relative;
  margin: 0 15px;
  text-align: center;
}

.testimonial .testimonial-img {
  position: relative;
  margin-bottom: 15px;
  z-index: 1;
}

.testimonial .testimonial-item img {
  margin: 0 auto;
  width: 150px;
  border: 15px solid #ffffff;
  border-radius: 100px;
}

.testimonial .testimonial-content {
  position: relative;
  margin-top: -90px;
  padding: 100px 25px 25px 25px;
  text-align: center;
  background: #FFD662;
  border-radius: 5px;
}

.testimonial .testimonial-item p {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}

.testimonial .testimonial-content h3 {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.testimonial .testimonial-content h4 {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}

.testimonial .owl-nav,
.testimonial .owl-dots {
  margin-top: 15px;
  text-align: center;
}

.testimonial .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #FFD662;
}

.testimonial .owl-dot.active {
  background: #00539C;
}


/*******************************/
/*********** Blog CSS **********/
/*******************************/
.blog {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.blog .blog-item {
  position: relative;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0 0 30px rgba(0, 0, 0, .1);
}

.blog .blog-item img {
  width: 100%;
  border-radius: 5px 5px 0 0;
}

.blog .blog-item h3 {
  margin: 0;
  padding: 25px 30px 5px 30px;
  font-size: 22px;
  font-weight: 300;
  color: #00539C;
}

.blog .blog-item .meta {
  padding: 0 30px 15px 30px;
}

.blog .blog-item .meta * {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #999999;
}

.blog .blog-item .meta i {
  margin: 0 2px 0 10px;
}

.blog .blog-item .meta i:first-child {
  margin-left: 0;
}

.blog .blog-item p {
  padding: 0 30px;
  font-size: 16px;
}

.blog .blog-item a.btn {
  position: relative;
  margin: 5px 30px 30px 30px;
  padding: 10px 20px;
  font-size: 14px;
  text-transform: uppercase;
  color: #000000;
  border: none;
  border-radius: 5px;
  background: #FFD662;
  transition: .3s;
}

.blog .blog-item:hover a.btn {
  color: #ffffff;
  background: #00539C;
}

.blog .blog-item a.btn:hover {
  color: #000000;
  background: #FFD662;
}


/*******************************/
/******* Call to Action ********/
/*******************************/
.call-to-action {
  position: relative;
  margin: 45px 0;
  padding: 60px 0;
  background: #00539C;
}

.call-to-action h2 {
  color: #ffffff;
  font-size: 45px;
  font-weight: 100;
  text-transform: uppercase;
}

.call-to-action p {
  margin: 0;
  color: #ffffff;
  font-size: 20px;
  font-weight: 300;
}

.call-to-action a {
  float: right;
  height: 60px;
  padding: 16px 35px 14px 35px;
  font-size: 18px;
  font-weight:400;
  text-transform: uppercase;
  color: #000000;
  background: #FFD662;
  border-radius: 5px;
}

.call-to-action a:hover {
  color: #FFD662;
  background: #ffffff;
}

@media (max-width: 768px) {
  .call-to-action a {
      float: left;
      margin-top: 30px;
  }
}


/*******************************/
/********** Story CSS **********/
/*******************************/
.story {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.story .container {
  max-width: 960px;
}

.story .story-container {
  position: relative;
  width: 100%;
}

.story .story-end,
.story .story-start,
.story .story-year {
  position: relative;
  width: 100%;
  text-align: center;
  z-index: 1;
}

.story .story-end p,
.story .story-start p,
.story .story-year p {
  display: inline-block;
  width: 100px;
  margin: 0;
  padding: 10px 0;
  text-align: center;
  background: #FFD662;
  border: 1px solid #00539C;
  border-radius: 5px;
  color: #000000;
  font-size: 16px;
  text-transform: uppercase;
}

.story .story-year {
  margin: 30px 0;
}

.story .story-continue {
  position: relative;
  width: 100%;
  padding: 60px 0;
}

.story .story-continue::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 100%;
  top: 0;
  left: 50%;
  margin-left: -1.5px;
  background: #FFD662;
  border-left: 1px solid #00539C;
  border-right: 1px solid #00539C;
}

.story .row.story-left,
.story .row.story-right .story-date {
  text-align: right;
}

.story .row.story-right,
.story .row.story-left .story-date {
  text-align: left;
}

.story .story-date {
  padding: 0 5px;
  font-size: 14px;
  font-weight: 400;
  margin: 41px 0 0 0;
}

.story .story-date::after {
  content: '';
  display: block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 42px;
  background: #FFD662;
  border: 1px solid #00539C;
  border-radius: 15px;
  z-index: 1;
}

.story .row.story-left .story-date::after {
  left: -8.5px;
}

.story .row.story-right .story-date::after {
  right: -8.5px;
}

.story .story-box,
.story .story-launch {
  position: relative;
  display: inline-block;
  margin: 15px;
  padding: 20px 25px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  background: #ffffff;
}

.story .story-launch {
  width: 100%;
  margin: 15px 0;
  padding: 0;
  border: none;
  text-align: center;
  background: transparent;
}

.story .story-box::after,
.story .story-box::before {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.story .row.story-left .story-box::after,
.story .row.story-left .story-box::before {
  left: 100%;
}

.story .row.story-right .story-box::after,
.story .row.story-right .story-box::before {
  right: 100%;
}

.story .story-launch .story-box::after,
.story .story-launch .story-box::before {
  left: 50%;
  margin-left: -10px;
}

.story .story-box::after {
  top: 26px;
  border-color: transparent transparent transparent #ffffff;
  border-width: 10px;
}

.story .story-box::before {
  top: 25px;
  border-color: transparent transparent transparent #dddddd;
  border-width: 11px;
}

.story .row.story-right .story-box::after {
  border-color: transparent #ffffff transparent transparent;
}

.story .row.story-right .story-box::before {
  border-color: transparent #dddddd transparent transparent;
}

.story .story-launch .story-box::after {
  top: -20px;
  border-color: transparent transparent #dddddd transparent;
}

.story .story-launch .story-box::before {
  top: -19px;
  border-color: transparent transparent #ffffff transparent;
  border-width: 10px;
  z-index: 1;
}

.story .story-box .story-text {
  position: relative;
  float: left;
}

.story .story-launch .story-text {
  max-width: 400px;
}

.story .story-text h3 {
  color: #00539C;
  font-size: 18px;
  font-weight: 400;
}

.story .story-text p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .story .story-continue::before {
      left: 48.5px;
  }
  
  .story .story-end,
  .story .story-start,
  .story .story-year,
  .story .row.story-left,
  .story .row.story-right .story-date,
  .story .row.story-right,
  .story .row.story-left .story-date,
  .story .story-launch {
      text-align: left;
  }
  
  .story .story-date {
      padding: 0;
  }
  
  .story .row.story-left .story-date::after,
  .story .row.story-right .story-date::after {
      left: 54.5px;
  }
  
  .story .story-box,
  .story .row.story-right .story-date,
  .story .row.story-left .story-date {
      margin-left: 77px;
  }
  
  .story .story-launch .story-box {
      margin-left: 0;
  }
  
  .story .row.story-left .story-box::after {
      left: -20px;
      border-color: transparent #ffffff transparent transparent;
  }

  .story .row.story-left .story-box::before {
      left: -22px;
      border-color: transparent #dddddd transparent transparent;
  }
  
  .story .story-launch .story-box::after,
  .story .story-launch .story-box::before {
      left: 30px;
      margin-left: 0;
  }
}


/*******************************/
/******** Portfolio CSS ********/
/*******************************/
.portfolio {
  position: relative;
  padding: 45px 0;
  background: #ffffff;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: -15px 0 25px 0;
  list-style: none;
  font-size: 0;
  text-align: center;
}

.portfolio #portfolio-flters li,
.portfolio .load-more .btn {
  cursor: pointer;
  display: inline-block;
  margin: 5px;
  padding: 12px 15px;
  color: #000000;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 5px;
  background: #FFD662;
  transition: .3s;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: #00539C;
  color: #ffffff;
}

.portfolio .load-more {
  text-align: center;
}

.portfolio .load-more .btn {
  padding: 15px 35px;
  font-size: 16px;
  transition: .3s;
}

.portfolio .load-more .btn:hover {
  color: #ffffff;
  background: #00539C;
}

.portfolio .portfolio-item {
  position: relative;
  margin-bottom: 30px;
  border-radius: 5px;
  overflow: hidden;
}

.portfolio .portfolio-item figure {
  background: #ffffff;
  overflow: hidden;
  height: 400px;
  position: relative;
  margin: 0; 
}

.portfolio .portfolio-item figure img {
  width: 100%; 
  height: 100%; 
  object-fit: cover;
  border-radius: 5px;
}

.portfolio .portfolio-item figure .link-preview,
.portfolio .portfolio-item figure .link-details {
  position: absolute;
  display: inline-block;
  opacity: 0;
  line-height: 1;
  text-align: center;
  width: 80px;
  height: 80px;
  top: -50px;
  background: #ffffff;
  transition: .3s;
}

.portfolio .portfolio-item figure .link-preview {
  left: -50px;
  border-radius: 0 80px;
}

.portfolio .portfolio-item figure .link-details {
  right: -50px;
  border-radius: 80px 0;
}

.portfolio .portfolio-item figure:hover .link-preview {
  top: 0;
  left: 0;
}

.portfolio .portfolio-item figure:hover .link-details {
  top: 0;
  right: 0;
}

.portfolio .portfolio-item figure .link-preview i,
.portfolio .portfolio-item figure .link-details i {
  color: #00539C;
  padding-top: 25px;
  font-size: 30px;
}

.portfolio .portfolio-item figure .link-preview:hover,
.portfolio .portfolio-item figure .link-details:hover {
  background: #00539C;
}

.portfolio .portfolio-item figure .link-preview:hover i,
.portfolio .portfolio-item figure .link-details:hover i {
  color: #ffffff;
}

.portfolio .portfolio-item figure:hover .link-preview,
.portfolio .portfolio-item figure:hover .link-details {
  opacity: 1;
}

.portfolio .portfolio-item figure .portfolio-title {
  position: absolute;
  margin: 0;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  text-align: center;
  color: #000000;
  font-size: 16px;
  text-transform: uppercase;
  background: #FFD662;
  border-radius: 0 0 5px 5px;
  transition: .3s;
}

.portfolio .portfolio-item figure .portfolio-title:hover {
  text-decoration: none;
  background: #00539C;
  color: #ffffff;
}


/*******************************/
/********* Contact CSS *********/
/*******************************/
.contact {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.contact .faqs {
  padding: 0;
}

@media(max-width: 767.98px) {
  .contact .faqs {
      margin-bottom: 30px;
  }
}

.contact .contact-form input {
  height: 60px;
  border-radius: 5px;
}

.contact .contact-form .btn {
  height: 60px;
  padding: 0 20px;
  color: #000000;
  font-size: 18px;
  text-transform: uppercase;
  background: #FFD662;
  border-radius: 5px;
  transition: .3s;
}

.contact .contact-form .btn:hover {
  color: #ffffff;
  background: #00539C;
}


/*******************************/
/******* Single Page CSS *******/
/*******************************/
.single {
  /* position: relative; */
  width: 100%;
  padding: 45px 0 65px 0;
}

.single h1,
.single h2,
.single h3,
.single h4,
.single h5,
.single h6 {
  font-weight: 400;
}

.single img {
  margin: 10px 0 15px 0;
}

.single .table,
.single .list-group {
  margin-bottom: 30px;
}

.single .section-header h2 {
  font-weight: 100;
}

.single .container-a{
  position: relative;
  text-align: center;
  color: blue;
  /* width: 300px;
  height: 200px; */
  top: 0px;
  /* border : cyan 3px solid; */

}
.single .container-a .theimg{
  position: relative;
}
.single .container-b{
  position: absolute;
  color: #666;
  width: 250px;
  height: 200px;
  /* border : blue 3px solid; */
  left: 55%;
  top: 30px;
  transform: translate(-50%);
}

.single .container-b .thetitle{
  position: relative;
  font-size: 18px;
  font-weight: 500;
  text-align: center;

}

.single .container-b .thetext-a{
  position: relative;
  text-align: left;
  margin-left: 10px;
  margin-top: 15px;
  color: black;
}

.single .thetext-a span{
  border-radius: 50%;
  background: #00539c;;
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  margin-right: 10px;
  color: #fff;
  font-size: 13px;
}

.single .container-b .thetext-b {
  position: relative;
  text-align: left;
  margin-left: 10px;
  margin-top: 10px;
  color: black;
}

.single .thetext-b span{
  border-radius: 50%;
  background: #00539c;;
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  margin-right: 10px;
  color: #fff;
  font-size: 13px;
}

.single .thetext-c{
  position: relative;
  text-align: left;
  margin-left: 40px;
  color: black;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





/* .single .container-a {
  position: relative;
}

.single .content-a {
  position: absolute;
  bottom: 400px;
  left: 50%;
  transform: translate(-40%, -60%);
} */

.single  .content-a  span{
  border-radius: 50%;
  background: #61a18d;
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 7px;
  margin-right: 15px;
  color: #fff;
  margin-top: 30px;
}

/* .single .content-b {
  position: absolute;
  bottom: 70%;
  left: 50%;
  transform: translate(-40%, -60%);
} */

/* .single .content-b span{
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 1px;
  background: blue;
  border: 2px solid #666;
  color: #666;
  text-align: center;

} */


/*******************************/
/********* Footer CSS **********/
/*******************************/
.footer {
  position: relative;
  margin-top: 45px;
  padding-top: 90px;
  background: #00539C;
  color: #ffffff;
  font-weight: 300;
}

.footer .footer-contact,
.footer .footer-link,
.footer .footer-form {
  position: relative;
  margin-bottom: 45px;
}

.footer .footer-contact h2,
.footer .footer-link h2,
.footer .footer-form h2{
  position: relative;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 400;
  color: #FFD662;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.footer .footer-contact p {
  font-size: 14px;
}

.footer .footer-contact p i {
  width: 25px;
}

.footer .footer-social {
  position: relative;
  margin-top: 25px;
}

.footer .footer-social a {
  display: inline-block;
}

.footer .footer-social a i {
  margin-right: 15px;
  font-size: 25px;
  color: #ffffff;
}

.footer .footer-social a:last-child i {
  margin: 0;
}

.footer .footer-social a:hover i {
  color: #FFD662;
}

.footer .footer-link a {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  color: #ffffff;
  
}

.footer .footer-link a::before {
  position: relative;
  content: "\f0da";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .footer-link a:hover {
  color: #FFD662;
}

.footer .footer-form input {
  height: 45px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
}

.footer .footer-form .btn {
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: 14px;
  font-weight:400;
  text-transform: uppercase;
  color: #000000;
  background: #FFD662;
  border-radius: 5px;
  transition: .3s;
}

.footer .footer-form .btn:hover {
  color: #ffffff;
  background: #00539C;
}

.footer .footer-menu {
  position: relative;
  padding: 15px 0;
  border-top: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu .f-menu {
  font-size: 0;
  text-align: center;
}

.footer .footer-menu .f-menu a {
  color: #ffffff;
  font-size: 14px;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #ffffff;
}

.footer .footer-menu .f-menu a:hover {
  color: #FFD662;
}

.footer .footer-menu .f-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}


.footer .copyright {
  padding: 30px 15px;
  border-top: 1px solid rgba(255, 255, 255, .1);
}

.footer .copyright p {
  margin: 0;
  font-size: 14px;
}

.footer .copyright .col-md-6:last-child p {
  text-align: right;
}

.footer .copyright p a {
  color: #FFD662;
  font-weight: 400;
}

.footer .copyright p a:hover {
  color: #ffffff;
}

@media (max-width: 768px) {
  .footer .copyright p,
  .footer .copyright .col-md-6:last-child p {
      margin: 5px 0;
      text-align: center;
  }
}
